import React, {Component} from 'react';
import AppContext from "../AppContext";

class BackButton extends Component<{
    goToPage: string
}> {
    static contextType = AppContext;

    onClickBack = () => this.context.ref.switchPage(this.props.goToPage);

    render() {
        return (
            <button className={'btn'} onClick={this.onClickBack}>&larr; Back</button>
        );
    }
}

export default BackButton;