import Welcome from "./pages/Welcome";
import GetMassIndex from "./pages/GetMassIndex";
import BmiResult from "./pages/BmiResult";
import EpworthCalculation from "./pages/EpworthCalculation";
import EpworthResult from "./pages/EpworthResult";
import Conclution from "./pages/Conclution";
import Syndrome1 from "./pages/Syndrome1";
import Syndrome2 from "./pages/Syndrome2";

const PageMap = {
    Welcome: Welcome,
    GetMassIndex: GetMassIndex,
    BmiResult: BmiResult,
    Syndrome1: Syndrome1,
    Syndrome2: Syndrome2,
    EpworthCalculation: EpworthCalculation,
    EpworthResult: EpworthResult,
    Conclution: Conclution
};

export default PageMap;