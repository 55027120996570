import React from "react";
import AppContext from "../AppContext";
import ContinueButton from "../components/ContinueButton";
import BackButton from "../components/BackButton";

class EpworthResult extends React.PureComponent {

    // noinspection JSUnusedGlobalSymbols
    static contextType = AppContext;

    renderContinueButton = () => (
        <>
            <BackButton goToPage={'EpworthCalculation'}/>
            <ContinueButton goToPage={'Conclution'}/>
        </>
    );

    renderNormal = () => (
        <div className={'App'}>
            <h1>You are most likely getting enought sleep</h1>
            <p>However, if you have noticed a change in your normal sleep routine, you may want to talk to your
                doctor.</p>

            {this.renderContinueButton()}
        </div>
    );

    renderExcessiveDailySleepiness = () => (
        <div className={'App'}>
            <h1>You may be suffering from excessive daytime sleepiness</h1>
            <p> It may be due to sleep apnea or other factors. Take an appointment with your doctor in order to know why
                you are so sleepy </p>

            {this.renderContinueButton()}
        </div>
    );

    renderDangerous = () => (
        <div className={'App'}>
            <h1>You are dangerously sleepy</h1>
            <p>That is to say that you may have an accident or some difficulties at work or in your family due to your
                excessive sleepiness.
                <br/>
                Avoid driving and take an appointment quickly with your doctor in order to know why you are so sleepy!
                <br/>
                It can be due to sleep apnea or to other problems.
            </p>

            {this.renderContinueButton()}
        </div>
    );

    render() {
        if (this.context.epworth < 10) {
            return this.renderNormal();
        }

        if (this.context.epworth <= 16) {
            return this.renderExcessiveDailySleepiness();
        }

        return this.renderDangerous();
    }
}

export default EpworthResult;