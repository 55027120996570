import React from "react";
import BackButton from "../components/BackButton";
import AppContext from "../AppContext";

class Conclution extends React.PureComponent {

    static contextType = AppContext;

    onClickComplete = () => window.location.href =
        this.context.language.match(/^vi/)
            ? 'https://www.hfh.com.vn/vi/299/dat-lich-kham-benh.html'
            : 'https://www.hfh.com.vn/en/299/make-appointment.html';

    render() {
        return (
            <div className={'App'}>
                <h1>Conclution</h1>

                <p>If at one point at that survey, you have been told that you may have sleep apnea, you should read
                    this factsheet about Sleep Apnea and take an appointment with your doctor to go further!</p>

                <p>At the contrary, if nothing points out sleep apnea you should see your doctor to look for another
                    explanation to the symptoms which lead you to complete this survey.</p>

                <p>Moreover, have a look on this factsheet because some people in your environment may suffer from sleep
                    apnea and you will help them a lot by sharing information and advices!</p>

                <p>Thank you for completing this survey and share it and leave us a comment!</p>

                <p>For more information about sleep apnea: <a href="https://www.hfh.com.vn/en/617/sleep-apnea.html" target={'_blank'}>Click here</a></p>
                {/*<fieldset className={'js-form-wrapper'}>*/}
                    {/*<legend>Your name:</legend>*/}
                    {/*<input className={'form-text'} type={'text'}/>*/}
                {/*</fieldset>*/}

                {/*<fieldset className={'js-form-wrapper'}>*/}
                    {/*<legend>Phone number:</legend>*/}
                    {/*<input className={'form-text'} type={'text'}/>*/}
                {/*</fieldset>*/}

                {/*<fieldset className={'js-form-wrapper'}>*/}
                    {/*<legend>Email:</legend>*/}
                    {/*<input className={'form-text'} type={'text'}/>*/}
                {/*</fieldset>*/}

                <BackButton goToPage={'EpworthResult'}/>
                <button style={{
                    marginLeft: '10px'
                }} className={'btn'} onClick={this.onClickComplete}>Complete!
                </button>
            </div>
        )
    }
}

export default Conclution