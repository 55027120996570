import vietnamese from "./lang/vietnamese";
import english from "./lang/english";

let currentLanguage = 'en-US';

const languageMap = {
    'en-US': english,
    'vi-VN': vietnamese
};

const translate = id => {
    if (languageMap[currentLanguage][id]) {
        return languageMap[currentLanguage][id];
    }

    return id;
};

const setLanguage = lang => currentLanguage = lang;

export {
    translate,
    setLanguage
}