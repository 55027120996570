import React from "react";
import AppContext from "../AppContext";
import BackButton from "../components/BackButton";
import ContinueButton from "../components/ContinueButton";

class GetMassIndex extends React.PureComponent {

    // noinspection JSUnusedGlobalSymbols
    static contextType = AppContext;

    onWeightChange = event => this.context.ref.setWeight(event.target.value);

    onHeightChange = event => this.context.ref.setHeight(event.target.value);

    render() {
        return <div className={'App'}>
            <h1>What is you body mass index?</h1>

            <p>Sleep apnea is often associated with overweight.</p>

            <p>To know if you have a correct weight for your height, you can calculate your Body Mass Index (BMI).<br/>This
                tool can help you!</p>

            <fieldset className={'js-form-wrapper'}>
                <legend>Enter your weight (Kg)</legend>
                <input className={'form-text'} type={'number'} step={0.01} onChange={this.onWeightChange}/>
            </fieldset>

            <fieldset className={'js-form-wrapper'}>
                <legend>Enter your height (cm)</legend>
                <input className={'form-text'} type={'number'} step={1} onChange={this.onHeightChange}/>
            </fieldset>
            {
                this.context.bmi > 0 && this.context.bmi !== Infinity && !isNaN(this.context.bmi)
                    ? <div>
                        <p><strong>Your BMI is: {this.context.bmi}</strong></p>

                        <BackButton goToPage={'Welcome'}/>
                        <ContinueButton goToPage={'BmiResult'}/>
                    </div>
                    : <BackButton goToPage={'Welcome'}/>
            }
        </div>
    }
}

export default GetMassIndex;