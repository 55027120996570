import React from "react";
import AppContext from "../AppContext";
import ContinueButton from "../components/ContinueButton";
import BackButton from "../components/BackButton";

class Syndrome2 extends React.PureComponent {

    // noinspection JSUnusedGlobalSymbols
    static contextType = AppContext;

    onClickContinue = () => this.context.ref.switchPage('EpworthCalculation');

    render() {
        return (
            <div className={'App'}>
                <h1>Do you have any of these syndrome?</h1>

                <p><label><input type="checkbox"/> Snoring?</label></p>
                <p><label><input type="checkbox"/> Stop breathing at night?</label></p>
                <p><label><input type="checkbox"/> Waking up at night with checst tighness?</label></p>
                <p><label><input type="checkbox"/> Urine frequently at night?</label></p>
                <p><label><input type="checkbox"/> Headaches when wake up in the morning?</label></p>
                <p><label><input type="checkbox"/> Memory or concentration difficulties?</label></p>

                <BackButton goToPage={'Syndrome1'}/><ContinueButton goToPage={'EpworthCalculation'}/>
            </div>
        )
    }
}

export default Syndrome2;