//@flow

import React, {Component} from 'react';
import './App.css';
import AppContext from "./AppContext";
import PageMap from "./PageMap";
import {setLanguage} from "./Language";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 'Welcome',
            language: 'en-US',
            weight: 0,
            height: 0,
            bmi: 0,
            epworth: 0,
            ref: this
        };
    }

    switchPage = (page, callback = null) => this.setState({currentPage: page}, callback);

    setWeight = (weight: number) => this.setState(state => {
        return {
            weight,
            bmi: this._getBmi(weight, state.height)
        }
    });

    setHeight = (height: number) => this.setState(state => {
        return {
            height,
            bmi: this._getBmi(state.weight, height)
        }
    });

    setEpworth = (number: number) => this.setState({epworth: number});

    _getBmi = (weight: number, height: number): number => Math.round((weight / ((height/100) * (height/100))) * 100) / 100;

    changeToAmerican = () => {
        setLanguage('en-US');
        this.setState({language: 'en-US'});
    };

    changeToVietnamese = () => {
        setLanguage('vi-VN');
        this.setState({language: 'vi-VN'});
    };

    render() {
        const CurrentPage = PageMap[this.state.currentPage];

        console.log('this.state.language', this.state.language);

        return <AppContext.Provider value={this.state}>
            <div>
                <div className={'language-wrapper'}>
                    <img className={`language-img ${this.state.language === 'vi-VN' ? 'active' : ''}`} onClick={this.changeToVietnamese}
                                  src="https://image.flaticon.com/icons/svg/581/581047.svg"
                         alt="Vietnamese"/>
                    <img className={`language-img ${this.state.language === 'en-US' ? 'active' : ''}`} onClick={this.changeToAmerican}
                         src="https://image.flaticon.com/icons/svg/581/581038.svg" alt="Vietnamese"/>
                </div>
                <CurrentPage/>
            </div>
        </AppContext.Provider>
    }
}

export default App;
