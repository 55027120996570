import React from "react";
import AppContext from "../AppContext";
import ContinueButton from "../components/ContinueButton";
import BackButton from "../components/BackButton";

class Syndrome1 extends React.PureComponent {

    // noinspection JSUnusedGlobalSymbols
    static contextType = AppContext;

    state = {
        arrythmia: false,
        high_blood_pressure: false,
        stroke: false
    };

    render() {
        return (
            <div className={'App'}>
                <h1>Do you have any of these symptom?</h1>

                <div>
                    <h2>Arrythmia</h2>
                    <p>Arrythmia is a trouble of your heart’s rythm. It occurs when the electrical impulses that direct
                        heartbeats don’t function properly: too fast, too slow, too early, too irregular.</p>
                    <p>The main synmptom is palpitations.</p>
                    <p>It can be due to a lot of diseases such as chronic cardiac diseases, or thyroïd problems. It can
                        also occurs in case of lack of oxygen, as it occurs during sleep apnea.</p>

                    <label>
                        <input type="checkbox"
                               onChange={event => this.setState({arrythmia: event.target.checked})}
                        />
                        Yes, I have
                    </label>
                </div>

                <div>
                    <h2>High blood pressure</h2>
                    <p>The blood pressure is the pressure of the blood flow from your heart to the blood vessels. A high
                        blood pressure is a pressure higher than usual. Over time, it hurts your vessels, makes your
                        heart weaker and can lead to heart attack and stroke.</p>
                    <p>High blood pressure is often silent!</p>
                    <p>High blood pressure can be due to a lot of factors, such as non healthy diet (too much salt,
                        smoking …), overweight, but also sleep apnea.</p>
                    <p>During sleep apnea, lack of oxygen during the night is stressing your heart, leading to high
                        blood pressure, often difficult to treat or occuring especially at night.</p>

                    <label>
                        <input type="checkbox"
                               onChange={event => this.setState({high_blood_pressure: event.target.checked})}
                        />
                        Yes, I have
                    </label>
                </div>

                <div>
                    <h2>Stroke (previous cerebro-vascular attack)</h2>
                    <p>A stroke occurs when blood flow to an area of brain is stopped by a clot or a bleeding It leads
                        to loss of control of abilities directed by the affected brain area : for example weakness of an
                        arm or leg, imapired speak...</p>
                    <p>There are a lot of causes of strokes, and one is sleep apnea thanks to high blood pressure and/or
                        arrythmia.</p>

                    <label>
                        <input type="checkbox"
                               onChange={event => this.setState({stroke: event.target.checked})}
                        />
                        Yes, I have
                    </label>
                </div>

                <BackButton goToPage={'BmiResult'}/> <ContinueButton goToPage={'Syndrome2'}/>
            </div>
        )
    }
}

export default Syndrome1;