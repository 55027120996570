import React, {Component} from 'react';
import AppContext from "../AppContext";

class ContinueButton extends Component<{
    goToPage: string
}> {
    static contextType = AppContext;

    onClickContinue = () => this.context.ref.switchPage(this.props.goToPage);

    render() {
        return (
            <button style={{
                marginLeft: '10px'
            }} className={'btn'} onClick={this.onClickContinue}>Continue &rarr;</button>
        );
    }
}

export default ContinueButton;