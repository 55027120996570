import React from "react";
import AppContext from "../AppContext";
import {translate} from "../Language";

class Welcome extends React.PureComponent {

    // noinspection JSUnusedGlobalSymbols
    static contextType = AppContext;

    onClickStart = () => this.context.ref.switchPage('GetMassIndex');

    render() {
        return (
            <div className="App">
                <h1>{translate('do_you_have_sleep_apnea')}</h1>

                <p>Does your partner tell you that you are snoring too loud, or that you are gasping during sleep?</p>
                <p>Do you wake up in the morning with headaches, feeling tired as if your sleep was unrestful?</p>
                <p>Do you feel tired all the day long, falling asleep during meetings or traffic jams?</p>
                <p>Do you have difficulty to concentrate at work?</p>

                <p><strong>If so, you may have sleep apnea!</strong></p>

                <p>This survey will help you to determine if you are at risk to have sleep apnea and will give you some informations about this condition: what is it, how to be sure that you have sleep apnea, and how to treat it!</p>

                <button className={'btn'} onClick={this.onClickStart}>
                    {translate('lets_start')}
                </button>
            </div>
        );
    }
}

export default Welcome;