import React from "react";
import AppContext from "../AppContext";
import ContinueButton from "../components/ContinueButton";
import BackButton from "../components/BackButton";

class EpworthCalculation extends React.PureComponent {

    // noinspection JSUnusedGlobalSymbols
    static contextType = AppContext;

    state = {
        score: {}
    };

    _getScore = () => Object.values(this.state.score).reduce((a,b) => (+a) + (+b), 0);

    renderSelector = (id: string) => (
        <select onChange={event => {
            const {value} = event.target;
            this.setState(state => ({
                score: {
                    ...state.score,
                    [id]: value
                }
            }), () => this.context.ref.setEpworth(this._getScore()))
        }}>
            <option value="0">Would never doze</option>
            <option value="1">Slight chance of dozing</option>
            <option value="2">Moderate chance of dozing</option>
            <option value="3">High chance of dozing</option>
        </select>
    );

    render() {
        return (
            <div className={'App'}>
                <h1>EPWORD SLEEPINESS SCALE</h1>
                <p><em>Patient self assessment questionaire</em></p>
                <br/>

                <p>Rate the chance that you would doze off during the following 8 routine daytime situations:</p>

                <table align="center">
                    <tbody align="left">
                    <tr>
                        <td>Sitting reading book</td>
                        <td>{this.renderSelector('sit_read')}</td>
                    </tr>
                    <tr>
                        <td>Watch television</td>
                        <td>{this.renderSelector('television')}</td>
                    </tr>
                    <tr>
                        <td>Sitting inactive in a public place (in a meeting,...)</td>
                        <td>{this.renderSelector('sit_inactive')}</td>
                    </tr>
                    <tr>
                        <td>Lying down to rest in the afternoon</td>
                        <td>{this.renderSelector('rest_afternoon')}</td>
                    </tr>
                    <tr>
                        <td>Sitting talking to someone</td>
                        <td>{this.renderSelector('sit_talk')}</td>
                    </tr>
                    <tr>
                        <td>Sitting quietly after lunch without alcohol</td>
                        <td>{this.renderSelector('sit_alcohol')}</td>
                    </tr>
                    <tr>
                        <td>In a car, stopped in traffict or lights</td>
                        <td>{this.renderSelector('stopped_in_traffic')}</td>
                    </tr>
                    <tr>
                        <td>In a car, as passenger for an hour</td>
                        <td>{this.renderSelector('passenger')}</td>
                    </tr>
                    </tbody>
                </table>

                <BackButton goToPage={'Syndrome2'}/><ContinueButton goToPage={'EpworthResult'}/>
            </div>
        )
    }
}

export default EpworthCalculation;