import React from "react";
import AppContext from "../AppContext";
import BackButton from "../components/BackButton";
import ContinueButton from "../components/ContinueButton";

class BmiResult extends React.PureComponent {

    // noinspection JSUnusedGlobalSymbols
    static contextType = AppContext;


    renderContinueButton = () => (
        <>
            <BackButton goToPage={'GetMassIndex'}/>
            <ContinueButton goToPage={'Syndrome1'}/>
        </>
    );

    renderUnderWeight = () => (
        <div className={'App'}>
            <h1>You are Underweight</h1>

            <h2>Your BMI is {this.context.bmi}, less than 18.5Kg/m<sup>2</sup></h2>

            <p>Being underweight can cause you health problems, especially on your bones, skin, hairs, and immune
                system.</p>
            <p>Underweight can lead to osteoporosis that is to say that your bones are fragile and can break easily.</p>
            <p>Your skin becomes thinner, you may loss your hair and have poor dental health.</p>
            <p>You are not getting enough nutrients to maintain your immune system strong, so you are at risk to get
                more often infections, such a cold ; you also may feel tired all the time.</p>
            <p>In women, underweight is associated with irregular periods and premature births.</p>
            <p>There are a lot of causes of underweight, such as poor social conditions, chronic diseases of the heart,
                lung, digestive system, thyroïd (…), chronic infections such as tuberculosis, cancers, mental
                illness…</p>
            <p>You should ask your doctor to help you to find why you are unederweight and to give you good advices to
                gain weight healthfully.</p>

            {this.renderContinueButton()}
        </div>
    );

    renderNormalWeight = () => (
        <div className={'App'}>
            <h1>You are Normal</h1>

            <h2>Your BMI is {this.context.bmi}, between 18.5 and 25, it is normal</h2>

            <p>Continue to have regular physical activity and good nutrition habitsto keep your body healthy and
                strong!</p>

            {this.renderContinueButton()}
        </div>
    );

    _getBmiName = () => {
        const {bmi} = this.context;
        if (bmi < 30) return 'Overweight';
        if (bmi < 35) return 'Mild obesity';
        if (bmi < 40) return 'Moderate obesity';
        return 'Severe obesity';
    };

    renderOverweight = () => (
        <div className={'App'}>
            <h1>Your BMI is {this.context.bmi}, you are {this._getBmiName()}</h1>

            <p>Overweight / obesity is a risk factor of many health problems, affecting your heart, metabolism, muscles
                and joints and... respiration!</p>

            <p>Overweight is a major cause of cardiovascular diseases, such as heart diseases and strokes. It is also a
                common cause of diabetes.</p>

            <p>Overweight is also affecting your joints while creating chronic high pressure on it (especially for your
                knees and hips) leading to chronic pain or swollen due to arthrosis.</p>
            <p>And last but not the least, it can impair your breathing: it is a common cause of obstructive sleep apnea
                syndrome, as you will discover later in this survey.</p>

            {this.renderContinueButton()}
        </div>
    );

    render() {
        const {bmi} = this.context;

        if (bmi < 18.5) return this.renderUnderWeight();

        if (bmi <= 25) return this.renderNormalWeight();

        return this.renderOverweight();
    }
}

export default BmiResult;